import { Dialog } from "@ariakit/react";
import { Link } from "@remix-run/react";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import parse from "html-react-parser";
import { Loader2, Trash2 } from "lucide-react";
import React from "react";
import { Button } from "~/components/button";
import { usePostComments } from "~/hooks/use-comments";
import { useCurrentUser } from "~/hooks/use-current-user";
import type { Mention, Tag } from "~/types";
import { cn } from "~/util/css";
import {
  hashtagOnCursor,
  mentionOnCursor,
  stripMentionsFormatter,
  stripTagsAndMentions,
} from "~/util/parse-tags";
import { addHashtagAndMentionLinks, parserOptions } from "./post";
import TagInput from "./tag-input";

interface PostCommentSectionProps {
  postId: string;
  onDeleteSuccess?: () => void;
  onDeleteComment?: (commentId: number) => void;
  className?: string;
  emptyElement?: React.ReactNode;
  preview?: boolean;
}

export default function PostCommentSection(props: PostCommentSectionProps) {
  const { comments, addComment, loading, deleteComment } = usePostComments(
    props.postId,
    props.preview,
  );
  const [currentUser] = useCurrentUser();
  const [content, setContent] = React.useState("");
  const [showDeleteCommentModal, setShowDeleteCommentModal] =
    React.useState(false);
  const [commentToDeleteId, setCommentToDeleteId] = React.useState<
    number | null
  >(null);

  const [mentions, setMentions] = React.useState<Mention[]>([]);
  const [tags, setTags] = React.useState<Tag[]>([]);
  const [filteredTags, setFilteredTags] = React.useState<Tag[]>([]);
  const [currentTagQuery, setCurrentTagQuery] = React.useState<string>("");

  React.useEffect(() => {
    if (!loading) {
      setContent("");
    }
  }, [loading]);

  React.useEffect(() => {
    fetch("/api/tags?count=1000")
      .then((res) => res.json())
      .then((data) => setTags(data.tags))
      .catch(console.error);
  }, []);

  React.useEffect(() => {
    if (!currentTagQuery || currentTagQuery?.length <= 1) {
      setFilteredTags([]);
      return;
    }
    const regex = new RegExp(currentTagQuery, "i");
    const filtered = tags
      .filter((tag: Tag) => regex.test(tag.name))
      .slice(0, 10);
    setFilteredTags(filtered);
  }, [currentTagQuery, tags]);

  const onTagInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ref: HTMLTextAreaElement | HTMLInputElement,
  ) => {
    const value = e.target.value;

    const cursorPosition = ref.selectionStart ?? -1;
    const hashtagQuery = hashtagOnCursor(
      stripTagsAndMentions(value),
      cursorPosition,
    );
    const mentionQuery = mentionOnCursor(
      stripTagsAndMentions(value),
      cursorPosition,
    );

    if (hashtagQuery && hashtagQuery.length >= 2)
      setCurrentTagQuery(hashtagQuery);
    else setCurrentTagQuery("");

    if (mentionQuery && mentionQuery.length >= 2) {
      try {
        fetch(`/api/mentions/search?query=${mentionQuery}`)
          .then((res) => res.json())
          .then((data) => setMentions(data.mentions));
      } catch (err) {
        console.error("Error fetching mentions:", err);
      }
    } else {
      setMentions([]);
    }

    setContent(stripMentionsFormatter(value));
  };

  React.useEffect(() => {
    if (!loading) {
      setContent("");
    }
  }, [loading]);

  return (
    <div id="comment-section" className="flex flex-col gap-4 h-full">
      <div
        className={cn("max-h-72", props.className, {
          "overflow-y-auto": !props.preview,
        })}
      >
        {comments.length > 0 && (
          <div className="flex flex-col items-start flex-1 gap-4">
            {comments.map((comment, index) => (
              <div
                id={`comment-${index}`}
                className="w-full flex flex-col gap-1"
                key={comment.id?.toString()}
              >
                <div className="flex gap-2">
                  <Link to={`/${comment.owner_username}`}>
                    <img
                      className="w-6 h-6 rounded-full bg-cover bg-center bg-no-repeat"
                      src={comment.owner_avatar_url || "/default-avatar.svg"}
                      alt="avatar"
                    />
                  </Link>
                  <div className="bg-white flex flex-1 px-2 pb-2 rounded-xl">
                    <div className="flex-1">
                      <div className="flex items-center gap-1 text-base">
                        <Link to={`/${comment.owner_username}`}>
                          <p className="font-bold">{comment.owner_username}</p>
                        </Link>
                        <span className="text-gray-500 text-sm font-normal">
                          {formatDistanceToNowStrict(
                            parseISO(comment.created_at),
                            {
                              addSuffix: true,
                            },
                          )}
                        </span>
                      </div>
                      <div className="flex gap-3 pt-1">
                        <div className="text-black font-berkeley-mono text-base font-lg whitespace-pre-wrap break-all flex-1">
                          {parse(
                            addHashtagAndMentionLinks(comment.content),
                            parserOptions,
                          )}
                        </div>
                      </div>
                    </div>
                    {comment.owner_username === currentUser?.username && (
                      <>
                        <button
                          onClick={() => {
                            setShowDeleteCommentModal(true);
                            setCommentToDeleteId(comment.id);
                          }}
                        >
                          <Trash2 />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {!comments.length && props.emptyElement && (
          <div className="p-2">{props.emptyElement}</div>
        )}
      </div>

      <form
        method="post"
        onSubmit={(e) => {
          e.preventDefault();
          addComment(content, currentUser);
        }}
      >
        <div className="flex px-2 mb-5 py-1 items-center gap-2 rounded-md border-[1.5px] border-[#6637CE] bg-[#BBDAFF]">
          <TagInput
            value={content}
            onChange={onTagInputChange}
            tags={filteredTags}
            mentions={mentions}
            placeholder="Uhu!"
            className={cn(
              "bg-white w-full text-base focus:outline-none placeholder:text-black/40 flex border-lg border-2 border-gray-200 rounded-md",
            )}
          />
          {loading ? (
            <Loader2 size={24} className="animate-spin" />
          ) : (
            <button
              type="submit"
              className={cn({
                "opacity-50": !currentUser,
              })}
              disabled={!currentUser || !content.trim().length}
            >
              <img
                className={cn("w-8 transition ease-in-out delay-50", {
                  "hover:translate-x-1": content.trim().length,
                })}
                src="/icons/send.svg"
                alt="comment"
              />
            </button>
          )}
        </div>
      </form>

      {commentToDeleteId && (
        <DeleteCommentModal
          commentId={commentToDeleteId}
          open={showDeleteCommentModal}
          onClose={() => setShowDeleteCommentModal(false)}
          onDeleteComment={async (commentId) => {
            await deleteComment(commentId);
            if (props.onDeleteSuccess) {
              props.onDeleteSuccess();
            }
          }}
        />
      )}
    </div>
  );
}

interface DeleteCommentModal {
  commentId: number;
  open: boolean;
  onClose: () => void;
  onDeleteComment?: (commentId: number) => void;
}

function DeleteCommentModal(props: DeleteCommentModal) {
  const [loading, setLoading] = React.useState(false);

  const handleDeleteComment = async () => {
    setLoading(true);
    if (props.onDeleteComment) {
      props.onDeleteComment(props.commentId);
    }

    setLoading(false);
    props.onClose();
  };

  return (
    <Dialog
      backdrop={false}
      open={props.open}
      onClose={props.onClose}
      className="relative w-fit min-w-[300px] lg:w-[500px] mx-4 z-50 bg-white border-2 border-black overflow-auto mh-[calc(100vh-2rem)] shadow-[10px_10px_0px_0px_#000] rounded-xl"
      render={(dialogProps) => (
        <div
          className={cn(
            "flex fixed z-50 top-0 p-4 lg:p-0 w-full h-full bg-primary/50 backdrop-blur-sm flex-col justify-center items-center",
            props.open ? "flex" : "hidden",
          )}
          onClick={(e) => e.preventDefault()}
        >
          <div {...dialogProps} />
        </div>
      )}
    >
      <div className="p-4 flex flex-col gap-4 h-full w-full z-[1]">
        <div className="flex flex-col gap-2">
          <p className="text-lg font-semibold">
            Are you sure you want to delete this comment?
          </p>
          <p>This action cannot be undone.</p>
        </div>
        <div className="flex items-center justify-end gap-4">
          <button onClick={props.onClose}>Cancel</button>
          <Button onClick={handleDeleteComment} disabled={loading}>
            {loading ? (
              <Loader2 size={24} className="animate-spin animate" />
            ) : (
              "Delete"
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
