import React from "react";
import { useAtom, userWalletsAtom } from "~/atoms";

export function useUserWallets() {
  const [userWallets, setUserWallets] = useAtom(userWalletsAtom);

  React.useEffect(() => {
    if (userWallets.length === 0) {
      fetch("/api/user-wallets")
        .then((res) => res.json())
        .then((res) => {
          setUserWallets(res.userWallets);
        })
        .catch((err) => console.error("Error fetching user wallets:", err));
    }
  }, [setUserWallets, userWallets.length]);

  return { userWallets };
}
