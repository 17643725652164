import {
  Hovercard,
  HovercardAnchor,
  HovercardDisclosure,
  HovercardProvider,
  Popover,
  PopoverDisclosure,
  PopoverProvider,
  useHovercardStore,
  usePopoverContext,
  usePopoverStore,
} from "@ariakit/react";
import { Slot } from "@radix-ui/react-slot";
import { Link } from "@remix-run/react";
import { useQuery } from "@tanstack/react-query";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import {
  AnimatePresence,
  motion,
  useAnimate,
  useDragControls,
  useMotionValue,
  useTransform,
} from "framer-motion";
import parse, {
  domToReact,
  type HTMLReactParserOptions,
  type Element,
} from "html-react-parser";
import {
  Check,
  Coins,
  Link2,
  MoreHorizontal,
  Pencil,
  Plus,
  Trash2,
  X,
} from "lucide-react";
import pluralize from "pluralize";
import React from "react";
import { postSlappedStickerAtom, slappedStickerAtom, useAtom } from "~/atoms";
import * as toast from "~/components/toast";
import { usePostComments } from "~/hooks/use-comments";
import { useCurrentUser } from "~/hooks/use-current-user";
import { useDebounce } from "~/hooks/use-debounce";
import { useIsCollapse } from "~/hooks/use-is-collapse";
import type { Post as PostPropType, PostSticker } from "~/types";
import { cn } from "~/util/css";
import { formatNumber } from "~/util/number";
import { Button } from "./button";
import { DeletePostModal } from "./delete-post-modal";
import { EditPostModal } from "./edit-post-modal";
import { MediaCarouselItem } from "./media-carousel";
import PostCommentSection from "./post-comment-section";
import { Progress } from "./progress";
import { TipPostModal } from "./tip-post-modal";

export type PostType = "image" | "video" | "media";

interface AnimatedPopoverProps {
  children: React.ReactNode;
  mounted: boolean;
  className?: string;
}

export function AnimatedPopover(props: AnimatedPopoverProps) {
  return (
    <AnimatePresence>
      {props.mounted && (
        <Popover
          alwaysVisible
          className={cn(
            "bg-white rounded-xl overflow-hidden z-40  mt-2 mb-3 border-2 border-black shadow-[4px_4px_0px_0px_#000]",
            props.className,
          )}
          render={
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{
                scale: 1,
                opacity: 1,
                transition: { duration: 0.3, type: "spring" },
              }}
              exit={{ scale: 0, opacity: 0, transition: { duration: 0.1 } }}
              onClick={(e) => e.preventDefault()}
            />
          }
          backdrop={<div onClick={(e) => e.preventDefault()} />}
        >
          {props.children}
        </Popover>
      )}
    </AnimatePresence>
  );
}

interface AnimatedHoverCardProps {
  children: React.ReactNode;
  mounted: boolean;
  className?: string;
}

export function AnimatedHoverCard(props: AnimatedHoverCardProps) {
  return (
    <AnimatePresence>
      {props.mounted && (
        <Hovercard
          alwaysVisible
          className={cn(
            "bg-white rounded-xl overflow-hidden z-50  mt-2 mb-3 border-2 border-black shadow-[4px_4px_0px_0px_#000]",
            props.className,
          )}
          render={
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{
                scale: 1,
                opacity: 1,
                transition: { duration: 0.3, type: "spring" },
              }}
              exit={{ scale: 0, opacity: 0, transition: { duration: 0.1 } }}
            />
          }
        >
          {props.children}
        </Hovercard>
      )}
    </AnimatePresence>
  );
}

interface ShareButtonProps {
  link: string;
  className?: string;
}

export function ShareButton(props: ShareButtonProps) {
  const popoverStore = usePopoverStore();
  const mounted = popoverStore.useState("mounted");

  const handleCopyLink = () => {
    navigator.clipboard.writeText(props.link);
    popoverStore.hide();
    toast.ok({ text: "Copied to clipboard" });
  };

  return (
    <PopoverProvider store={popoverStore} placement="bottom-start">
      <PopoverDisclosure
        toggleOnClick={(e) => {
          e.preventDefault();
          popoverStore.toggle();
          return false;
        }}
        render={
          <Button
            variant="secondary"
            className={cn(
              "bg-none bg-transparent border border-[#6637CE]",
              props.className,
            )}
          />
        }
      >
        <span>Share</span>
      </PopoverDisclosure>
      <AnimatedPopover mounted={mounted} className="w-60">
        <ul>
          <li className="border-b">
            <button
              type="button"
              className="hover:bg-gray-300 p-3 w-full flex items"
              onClick={handleCopyLink}
            >
              <Link2 className="mr-4" />
              <strong>Copy link</strong>
            </button>
          </li>
        </ul>
      </AnimatedPopover>
    </PopoverProvider>
  );
}

interface StickerPopoverProps {
  sticker: PostSticker;
}

export function StickerPopover(props: StickerPopoverProps) {
  const popoverStore = useHovercardStore();
  const mounted = popoverStore.useState("mounted");

  return (
    <HovercardProvider store={popoverStore} placement="bottom-start">
      <HovercardAnchor
        className="w-20 h-20"
        style={{
          backgroundImage: `url(${props.sticker?.image_url})`,
          rotate: `${props.sticker.rotation_angle}deg`,
          top: `${props.sticker.position_y}%`,
          left: `${props.sticker.position_x}%`,
        }}
        render={(anchorProps) => (
          <motion.a
            {...anchorProps}
            initial={{ scale: 0 }}
            animate={{
              scale: 1,
              transition: { delay: Math.random() / 2, type: "spring" },
            }}
            whileHover={{
              scale: 1.15,
              transition: { duration: 0.25, type: "ease-in-out" },
            }}
            className="cursor-pointer absolute z-20 drop-shadow-md w-20 h-20 aspect-square bg-contain bg-center bg-no-repeat"
            href={`/sticker/${props.sticker.sticker_id}`}
          />
        )}
      />
      <HovercardDisclosure
        onFocusVisible={(e) => {
          e.preventDefault();
          popoverStore.toggle();
          return false;
        }}
      />
      <AnimatedHoverCard mounted={mounted} className="m-[-20px]">
        <div className="flex flex-col items-start gap-1 p-2">
          <div className="w-full p-1">
            <p className="text-xs">Slapped by</p>
            <Link
              to={`/${props.sticker.owner_username}`}
              className="flex items-center gap-1"
            >
              <div
                style={{
                  backgroundImage: `url(${
                    props.sticker.owner_avatar_url || "/default-avatar.svg"
                  })`,
                  backgroundSize: "cover",
                }}
                className="rounded-full h-6 aspect-square border-2 border-gray-900 bg-no-repeat bg-center"
              />
              <div className="text-sm w-30 text-[#B999FF] font-semibold">
                {props.sticker.owner_username}
              </div>
            </Link>
          </div>
        </div>
      </AnimatedHoverCard>
    </HovercardProvider>
  );
}

interface PostStickers {
  children: React.ReactNode;
  postId: string;
  className?: string;
  stickers: PostSticker[];
  isLoading: boolean;
}

export function PostStickers(props: PostStickers) {
  const [showStickers, setShowStickers] = React.useState(true);
  const [slappedSticker, _] = useAtom(slappedStickerAtom);
  const [stickers, setStickers] = React.useState<PostSticker[]>(props.stickers);

  React.useEffect(() => {
    setStickers(props.stickers);
  }, [props.stickers]);

  React.useEffect(() => {
    if (
      !slappedSticker?.post_id ||
      slappedSticker.post_id.toString() !== props.postId.toString()
    )
      return;

    setStickers((currentStickers) => [...currentStickers, slappedSticker]);
  }, [slappedSticker, props.postId]);

  return (
    <div className={cn("relative", props.className)}>
      {stickers.length > 0 && (
        <div className="absolute z-[45] right-6 top-5 w-[205px] bg-green flex justify-end">
          <div className="flex items-center gap-2 border-2 bg-white border-primary-400 rounded-full pl-2 p-px">
            <button
              type="button"
              className="flex items-center gap-1 text-sm"
              onClick={() => setShowStickers(!showStickers)}
            >
              <img
                className="h-5 w-5"
                src={`/icons/${
                  showStickers ? "visibility_off" : "visibility"
                }.svg`}
                alt="icon"
              />
              <p className="whitespace-nowrap sm:flex hidden">
                {pluralize(
                  showStickers ? "Hide Stickers" : "Show Stickers",
                  stickers.length,
                )}
              </p>
            </button>
            <div className="flex items-center font-bold bg-primary-200 border-2 border-primary-400 rounded-full p-1 text-sm gap-1.5">
              <img
                className="h-5 w-5"
                src="/icons/smile-light.svg"
                alt="slapped stickers"
              />
              x{stickers.length}
            </div>
          </div>
        </div>
      )}
      {showStickers &&
        !props.isLoading &&
        stickers.map((sticker) => (
          <StickerPopover
            key={sticker.sticker_id + sticker.owner_username + sticker.post_id}
            sticker={sticker}
          />
        ))}
      {props.children}
    </div>
  );
}

export interface PostProps {
  children: React.ReactNode;
  className?: string;
}

export function Post(props: PostProps) {
  return (
    <div
      className={cn(
        "border border-primary rounded-2xl overflow-hidden relative z-[1]",
        props.className,
      )}
    >
      {props.children}
    </div>
  );
}

interface PostInfoProps {
  children: React.ReactNode;
}

export function PostInfo(props: PostInfoProps) {
  return (
    <div className="flex items-center justify-between">{props.children}</div>
  );
}

interface PostUserProps {
  className?: string;
  textClassName?: string;
  avatarUrl: string;
  username: string;
}

export function PostUser(props: PostUserProps) {
  return (
    <Link to={`/${props.username?.toLowerCase()}`}>
      <div className="flex items-center gap-1 sm:gap-2">
        <div
          style={{
            backgroundImage: `url(${props.avatarUrl || "/default-avatar.svg"})`,
            backgroundSize: "cover",
          }}
          className={cn(
            "rounded-full h-7 w-7 border border-black bg-no-repeat bg-center",
            props.className,
          )}
        />
        <div
          className={cn(
            "text-sm sm:text-base w-32 sm:w-auto truncate font-bold",
            props.textClassName,
          )}
        >
          {props.username}
        </div>
      </div>
    </Link>
  );
}

interface PostMenuProps {
  createdAt: string;
  stickerCount: number;
  children?: React.ReactNode;
}

export function PostMenu(props: PostMenuProps) {
  return (
    <div className="flex items-center gap-3 sm:gap-2">
      {Number(props.stickerCount) > 0 && (
        <h2 className="font-medium text-[#6637CE] border-r pr-2 border-[#5B6684] flex">
          {formatNumber(props.stickerCount)}{" "}
          {pluralize("stickers", Number(props.stickerCount))}
        </h2>
      )}
      <span className="text-sm whitespace-nowrap">
        {formatDistanceToNowStrict(parseISO(props.createdAt), {
          addSuffix: true,
        })}
      </span>
      {props.children}
    </div>
  );
}

export function PostMenuPopover(props: {
  children: React.ReactNode;
  smallerButton?: boolean;
}) {
  const popoverStore = usePopoverStore();
  const mounted = popoverStore.useState("mounted");

  return (
    <PopoverProvider store={popoverStore} placement="bottom-start">
      <PopoverDisclosure
        toggleOnClick={(e) => {
          popoverStore.toggle();
          return false;
        }}
        render={
          <button
            className={`${
              props.smallerButton ? "w-6" : "w-8"
            } h-6 rounded-lg hover:bg-gray-100 flex items-center justify-center`}
            aria-label="menu"
            type="button"
          />
        }
      >
        <MoreHorizontal size={20} />
      </PopoverDisclosure>
      <AnimatedPopover mounted={mounted} className="w-60 z-[46] origin-top">
        <div className="flex flex-col">{props.children}</div>
      </AnimatedPopover>
    </PopoverProvider>
  );
}

interface PostMenuPopoverItemProps {
  children: React.ReactNode;
  asChild?: boolean;
  onClick?: () => void;
  classes?: string;
  disabled?: boolean;
}

export function PostMenuPopoverItem(props: PostMenuPopoverItemProps) {
  const { asChild, classes, ...rest } = props;
  const Comp = asChild ? Slot : "button";
  return (
    <Comp
      className={`flex gap-2 items-center p-4 w-full hover:bg-gray-100 ${classes}`}
      disabled={props.disabled}
      {...rest}
    />
  );
}

interface DeletePostPopoverItemProps {
  navigateTo: string;
  postId: string;
  onDeleteSuccess?: () => void;
}

export function DeletePostPopoverItem(props: DeletePostPopoverItemProps) {
  const [showDeletePostModal, setShowDeletePostModal] = React.useState(false);
  return (
    <>
      <PostMenuPopoverItem onClick={() => setShowDeletePostModal(true)}>
        <Trash2 />
        Delete post
      </PostMenuPopoverItem>
      {showDeletePostModal && (
        <DeletePostModal
          postId={props.postId}
          open={showDeletePostModal}
          onClose={() => setShowDeletePostModal(false)}
          navigateTo={props.navigateTo}
          onDeleteSuccess={props.onDeleteSuccess}
        />
      )}
    </>
  );
}

interface EditPostPopoverItemProps {
  postId: string;
  postContent: string;
}

export function EditPostPopoverItem(props: EditPostPopoverItemProps) {
  const [showEditPostModal, setShowEditPostModal] = React.useState(false);
  const popoverStore = usePopoverContext();
  return (
    <>
      <PostMenuPopoverItem onClick={() => setShowEditPostModal(true)}>
        <Pencil />
        Edit post
      </PostMenuPopoverItem>
      {showEditPostModal && (
        <EditPostModal
          postId={props.postId}
          postContent={props.postContent}
          open={showEditPostModal}
          onClose={() => setShowEditPostModal(false)}
          onSuccess={popoverStore?.hide}
        />
      )}
    </>
  );
}

interface TipPostPopoverItemProps {
  postId: string;
  postOwnerId: string;
}

export function TipPostPopoverItem(props: TipPostPopoverItemProps) {
  const [showTipPostModal, setShowTipPostModal] = React.useState(false);
  const [postOwnerAddress, setPostOwnerAddress] = React.useState(null);
  React.useEffect(() => {
    fetch(`/api/users/${props.postOwnerId}/address`)
      .then((res) => res.json())
      .then((res) => {
        if (res.primaryWalletAddress) {
          setPostOwnerAddress(res.primaryWalletAddress.address);
        }
      })
      .catch((error) => {
        console.error("Error getting post owner address", error);
      });
  }, [props.postOwnerId]);

  return (
    <>
      {postOwnerAddress ? (
        <>
          <PostMenuPopoverItem onClick={() => setShowTipPostModal(true)}>
            <Coins /> Tip
          </PostMenuPopoverItem>
          {showTipPostModal && (
            <TipPostModal
              postId={props.postId}
              postOwnerAddress={postOwnerAddress}
              postOwnerId={props.postOwnerId}
              open={showTipPostModal}
              onClose={() => setShowTipPostModal(false)}
            />
          )}
        </>
      ) : null}
    </>
  );
}

interface PostContentProps {
  children: React.ReactNode;
  className?: string;
}

export function PostContent(props: PostContentProps) {
  return (
    <div
      className={cn(
        "py-6 px-4 flex flex-col gap-4 bg-white whitespace-pre-wrap",
        props.className,
      )}
    >
      {props.children}
    </div>
  );
}

export function addHashtagAndMentionLinks(text: string) {
  const hashtagRegex = /#(\w+)/g;
  const mentionRegex = /@\[(.+?) \((.+?)\)\]\((\d+)\)/g;

  let newText = text.replace(hashtagRegex, '<a href="/hashtag/$1">#$1</a>');

  newText = newText.replace(mentionRegex, (match, mention, type, id) => {
    let url = "";
    switch (type) {
      case "gamer":
        url = `/${mention.toLowerCase()}`;
        break;
      case "game":
        url = `/games/${id}`;
        break;
      case "community":
        url = `/communities/${id}`;
        break;
      default:
        return match;
    }
    return `<a href="${url}" class="text-blue-400 font-medium hover:underline">@${mention}</a>`;
  });

  return newText;
}

export const parserOptions: HTMLReactParserOptions = {
  replace(node) {
    const domNode = node as Element;
    const { attribs, children } = domNode;
    if (!attribs) {
      return false;
    }
    const internalLinks = ["/quests/", "/games/", "/hashtag/", "/communities/"];
    if (internalLinks.some((link) => attribs.href.startsWith(link))) {
      return (
        <Link
          to={attribs.href}
          className="text-blue-400 font-medium hover:underline"
        >
          {domToReact(children)}
        </Link>
      );
    }
    return false;
  },
};

interface PostBody {
  contentBody: string;
}

export function PostBody(props: PostBody) {
  return (
    <div className="max-h-[120px] overflow-y-auto">
      {parse(addHashtagAndMentionLinks(props.contentBody), parserOptions)}
    </div>
  );
}

interface PostCallToActionsProps {
  children: React.ReactNode;
}

export function PostCallToActions(props: PostCallToActionsProps) {
  return <div className="flex gap-4 items-center">{props.children}</div>;
}

export function PostMediaFallback() {
  return (
    <div className="w-full sm:h-[415px] md:h-[600px] bg-white rounded-lg" />
  );
}

interface PostSlapBoxProps {
  postId: string;
  className?: string;
}

const slapDuration = 4000;
const stickerSize = 80;
const container = {
  padding: 16,
  border: 2,
};

export function PostSlapBox(props: PostSlapBoxProps) {
  const [scope, animate] = useAnimate();
  const [sticker, setSticker] = useAtom(postSlappedStickerAtom);
  const [_, setSlapped] = useAtom(slappedStickerAtom);
  const [percentage, setPercentage] = React.useState(100);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const currentPostSticker = sticker[props.postId];
  const [stickerPosition, setStickerPosition] = React.useState<{
    x: number | null;
    y: number | null;
    rotate: number;
  }>({ x: null, y: null, rotate: 0 });

  const [containerSize, setContainerSize] = React.useState({
    width: 0,
    height: 0,
  });

  const debouncedStickerPosition = useDebounce(stickerPosition, slapDuration);
  const motionX = useMotionValue(0);
  const motionY = useMotionValue(0);
  const rotateHandleY = useMotionValue(0);
  const rotate = useTransform(rotateHandleY, [-180, 180], [-360, 360]);
  const dragControlsRotate = useDragControls();
  const dragControlsSticker = useDragControls();

  const [isCollapse] = useIsCollapse();

  const calculateCenter = (dimension: number, defaultCoord: number) =>
    (dimension / 2 <= 0 ? defaultCoord : dimension / 2) -
    stickerSize / 2 +
    container.padding +
    container.border;

  const calculatePixelPosition = (
    stickerCoord: number | null,
    center: number,
    divDimension: number,
  ) =>
    Math.max(
      container.padding,
      Math.min(
        (stickerCoord ?? 0) + center + container.padding + container.border,
        divDimension - stickerSize - container.border,
      ),
    );

  const calculateValues = (clientWidth: number, clientHeight: number) => {
    const centerX = calculateCenter(clientWidth, 226);
    const centerY = calculateCenter(clientHeight, 188);

    const divWidth = clientWidth + container.padding * 2 + container.border;
    const divHeight = clientHeight + container.padding * 2 + container.border;

    return {
      centerX,
      centerY,
      divWidth,
      divHeight,
    };
  };

  const calculateScale = (
    xCoord: number,
    yCoord: number,
    maxDistanceFromEdge: number,
    scaleThreshold: number,
  ) => {
    const values = calculateValues(
      scope.current?.clientWidth,
      scope.current?.clientHeight,
    );
    const { divWidth, divHeight } = values;
    const distanceToRight = Math.max(divWidth - stickerSize - xCoord, 0);
    const distanceToBottom = Math.max(divHeight - stickerSize - yCoord, 0);
    const withinBounds =
      distanceToRight <= maxDistanceFromEdge &&
      distanceToBottom <= maxDistanceFromEdge;

    if (withinBounds) {
      const scaleValue = Math.max(
        scaleThreshold,
        Math.min(distanceToRight, distanceToBottom) / maxDistanceFromEdge,
      );
      return scaleValue;
    }

    return 1;
  };

  const imageScale = useTransform([motionX, motionY], ([motionX, motionY]) =>
    calculateScale(Number(motionX), Number(motionY), 60, 0.5),
  );

  const setDefaults = () => {
    setStickerPosition({ x: null, y: null, rotate: 0 });
    setPercentage(100);
    setIsSubmitting(false);
    rotateHandleY.set(0);
  };

  const slapSticker = () => {
    if (
      !currentPostSticker ||
      !stickerPosition.x ||
      !stickerPosition.y ||
      isSubmitting
    )
      return;

    setIsSubmitting(true);
    setPercentage(0);
    toast.loading({
      text: "Slapping sticker...",
      id: "slap",
      duration: 10000,
    });

    const values = calculateValues(
      scope.current.clientWidth,
      scope.current.clientHeight,
    );
    const { centerX, centerY, divHeight, divWidth } = values;

    const pixelPositionX = calculatePixelPosition(
      stickerPosition.x - container.border,
      centerX,
      divWidth,
    );
    const pixelPositionY = calculatePixelPosition(
      stickerPosition.y - container.border,
      centerY,
      divHeight,
    );

    const positionX = (pixelPositionX / divWidth) * 100;
    const positionY = (pixelPositionY / divHeight) * 100;

    fetch("/api/slap-sticker", {
      method: "POST",
      body: JSON.stringify({
        stickerId: currentPostSticker.stickerId.toString(),
        postId: currentPostSticker.postId.toString(),
        ownerId: currentPostSticker.ownerId,
        rotationAngle: stickerPosition.rotate,
        positionX,
        positionY,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          setSticker((stickers: any) => ({
            ...stickers,
            [props.postId]: null,
          }));
          setSlapped({
            sticker_id: currentPostSticker.stickerId,
            post_id: currentPostSticker.postId,
            image_url: currentPostSticker.imageUrl,
            rotation_angle: stickerPosition.rotate,
            position_x: positionX,
            position_y: positionY,
            created_at: new Date().toISOString(),
            owner_username: currentPostSticker.ownerUsername,
            owner_avatar_url: currentPostSticker.ownerAvatarUrl,
          });

          const text = data.earned
            ? `Earned ${formatNumber(data.points)} DRIP for slapping.`
            : "Sticker slapped!";

          toast.ok({
            text,
            id: "slap",
          });
        } else {
          toast.fail({
            text: "Error slapping sticker. Please try again.",
            id: "error",
          });
        }
      })
      .finally(() => {
        debouncedStickerPosition.x = null;
        debouncedStickerPosition.y = null;
        debouncedStickerPosition.rotate = 0;
        setDefaults();
      });
  };

  React.useEffect(() => {
    const currentScope = scope?.current;
    if (!currentScope) return;

    const timeoutId = setTimeout(() => {
      const { centerX, centerY, divWidth, divHeight } = calculateValues(
        currentScope.clientWidth,
        currentScope.clientHeight,
      );
      setContainerSize({ width: divWidth, height: divHeight });

      animate(
        "#slapped-sticker",
        {
          x: centerX,
          y: centerY,
          scale: 0,
          opacity: 0,
        },
        { duration: 0 },
      );

      if (currentPostSticker) {
        setDefaults();
        animate(
          "#slapped-sticker",
          {
            x: centerX,
            y: centerY,
            scale: 1,
            opacity: 1,
          },
          { delay: 0.25, type: "spring" },
        );
      }
    }, 400);

    // Cleanup the timeout if the component unmounts or dependencies change
    return () => clearTimeout(timeoutId);
  }, [currentPostSticker, isCollapse]);

  React.useEffect(() => {
    if (
      !currentPostSticker ||
      !debouncedStickerPosition.x ||
      !debouncedStickerPosition.y ||
      isSubmitting
    )
      return;
    slapSticker();
  }, [debouncedStickerPosition, currentPostSticker]);

  React.useEffect(() => {
    if (!stickerPosition.x || !stickerPosition.y || isSubmitting) return;

    // Reset percentage to 100 when stickerPosition.x changes
    setPercentage(100);

    const interval = setInterval(() => {
      setPercentage((prevPercentage) => {
        if (prevPercentage === 0) {
          clearInterval(interval);
          return 0;
        }
        return prevPercentage - 1;
      });
    }, slapDuration / 100);

    return () => clearInterval(interval);
  }, [stickerPosition.x]);

  return (
    <div
      ref={scope}
      className={cn(
        "absolute m-4 w-[calc(100%-34px)] h-[95%] max-h-[94%] transition ease-in-out",
        {
          "z-40 bg-opacity-60 border-2 border-dashed border-primary rounded-xl bg-primary/60 ":
            sticker && currentPostSticker,
        },
        props.className,
      )}
    >
      {sticker && currentPostSticker && (
        <div className="flex flex-row absolute p-2 bg-indigo-800 bg-opacity-50 w-full items-center h-11">
          <X
            onClick={() => {
              setSticker((stickers: any) => ({
                ...stickers,
                [props.postId]: null,
              }));
              setDefaults();
            }}
            className="rounded-full border border-black absolute -top-4 -left-4 cursor-pointer h-8 w-8 border-2 hover:bg-pink-200 stroke-black bg-white transition ease-in-out"
          />
          <Progress
            value={percentage}
            indicatorClassName="bg-[#F87171] border-1 border-[#EF4444] rounded-full"
            className="shadow-[0px_0px_4px_0px_rgba(0,0,0,0.2)] w-32 sm:w-64 h-4 border-2 border-[#705EE3] bg-white rounded-full sm:ml-4 "
          />
          <Check
            onClick={slapSticker}
            className={cn(
              "rounded-full border-black border-2 hover:bg-pink-200 bg-white cursor-pointer h-6 w-6 ml-2 hover:bg-pink-200 transition ease-in-out",
              {
                hidden: percentage === 100 && !isSubmitting,
                "opacity-50 cursor-not-allowed": isSubmitting,
              },
            )}
          />
        </div>
      )}
      <motion.div
        id="slapped-sticker"
        dragConstraints={{
          top: 0,
          bottom:
            containerSize.height -
            stickerSize -
            container.padding -
            container.border,
          left: 0,
          right:
            containerSize.width -
            stickerSize -
            container.padding -
            container.border,
        }}
        drag={!isSubmitting}
        dragControls={dragControlsSticker}
        dragListener={false}
        whileDrag={{ scale: 1.1 }}
        dragMomentum={false}
        onDragEnd={async (e, info) => {
          const values = calculateValues(
            scope.current.clientWidth,
            scope.current.clientHeight,
          );
          const { centerX, centerY, divHeight, divWidth } = values;
          const pixelPositionX =
            calculatePixelPosition(stickerPosition.x, centerX, divWidth) +
            info.offset.x;
          const pixelPositionY =
            calculatePixelPosition(stickerPosition.y, centerY, divHeight) +
            info.offset.y;

          const withinDeleteBounds =
            pixelPositionX >= divWidth - container.padding - stickerSize - 30 &&
            pixelPositionY >= divHeight - container.padding - stickerSize - 30;
          if (withinDeleteBounds) {
            setSticker((stickers: any) => ({
              ...stickers,
              [props.postId]: null,
            }));
            setStickerPosition({ x: null, y: null, rotate: 0 });
            return;
          }
          setStickerPosition((prevPosition) => ({
            x: (prevPosition.x ?? 0) + info.offset.x,
            y: (prevPosition.y ?? 0) + info.offset.y,
            rotate: prevPosition.rotate,
          }));
        }}
        className={cn("absolute flex flex-row-reverse place-items-start", {
          "animate-pulse": isSubmitting,
        })}
        style={{
          x: motionX,
          y: motionY,
          rotate,
        }}
        transition={{ type: "spring" }}
      >
        <div
          className="w-6 h-6 bg-no-repeat bg-center bg-contain bg-[url('/icons/rotate.svg')] absolute cursor-grab hover:scale-1.1 -top-6 -right-6"
          onPointerDown={(e) => {
            e.preventDefault();
            dragControlsRotate.start(e);
          }}
        />
        <motion.div
          dragControls={dragControlsRotate}
          style={{ y: rotateHandleY }}
          drag={isSubmitting ? false : "y"}
          dragMomentum={false}
          dragListener={false}
          dragElastic={0}
          onDragEnd={() => {
            setStickerPosition((prevPosition) => ({
              x: prevPosition.x,
              y: prevPosition.y,
              rotate: rotate.get(),
            }));
          }}
        />
        <motion.div
          onPointerDown={(e) => {
            e.preventDefault();
            dragControlsSticker.start(e);
          }}
          className=" w-20 hover:cursor-grab aspect-square bg-contain bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${currentPostSticker?.imageUrl})`,
            scale: imageScale,
          }}
        />
      </motion.div>
      <AnimatePresence>
        {currentPostSticker && (
          <div className="group absolute flex items-end rounded-xl z-10 right-0 cursor-pointer bottom-0 justify-end h-16 w-16">
            <Trash2 className="p-1 m-2 rounded-full border border-black h-8 w-8 border-2 group-hover:border-white group-hover:bg-gray-950 group-hover:stroke-white stroke-black bg-white transition ease-in-out" />
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface PostCommentProps {
  postId: string;
  commentCount: number;
  onDeleteSuccess: () => void;
}

export function PostComment(props: PostCommentProps) {
  const { comments } = usePostComments(props.postId);

  return (
    <div className="bg-[rgba(199,231,255,0.4)] p-4 flex flex-col gap-4">
      {Number(comments.length) > 1 && (
        <Link
          to={`/post/${props.postId}#comment-section`}
          className="font-medium text-[#6637CE] text-sm"
        >
          View more comments
        </Link>
      )}
      <PostCommentSection
        preview={true}
        postId={props.postId}
        onDeleteSuccess={props.onDeleteSuccess}
      />
    </div>
  );
}

interface PostAvailableStickersProps {
  postId: string;
  ownerId: string;
  maxDisplayStickers?: number;
}

const MAX_AVAILABLE_STICKERS = 12;

const getAvailableStickers = async (
  postId: string,
  userId: string,
): Promise<
  {
    id: string;
    image_url: string;
    already_posted: boolean;
    owner_username: string;
    owner_avatar_url: string;
  }[]
> => {
  const res = await fetch(
    `${window.ENV.EDGE_URL}/stickers/available?postId=${postId}&userId=${userId}`,
  );
  return await res.json();
};

export function PostAvailableStickers(props: PostAvailableStickersProps) {
  const [, setPostSlappedStickers] = useAtom(postSlappedStickerAtom);
  const [slapped] = useAtom(slappedStickerAtom);
  const [currentUser] = useCurrentUser();

  const { data, refetch } = useQuery({
    queryKey: ["available-stickers", props.postId, currentUser?.id],
    queryFn: () =>
      getAvailableStickers(props.postId, currentUser?.id as string),
    enabled: Boolean(currentUser?.id),
  });

  const availableStickers = data || [];

  React.useEffect(() => {
    // refetch available post stickers when user slapped
    if (slapped?.post_id === props.postId) {
      refetch();
    }
  }, [slapped, props.postId]);

  const maxDisplayStickers = props.maxDisplayStickers ?? MAX_AVAILABLE_STICKERS;

  return availableStickers.length > 0 ? (
    <div className="border-t border-primary mt-1 pt-4 flex items-center gap-[10px] flex-wrap min-h-[74px] xl:min-h-[32px] max-h-[100px] overflow-hidden">
      {availableStickers.slice(0, maxDisplayStickers).map((sticker) => (
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            if (sticker.already_posted) {
              return;
            }
            setPostSlappedStickers((prevStickers: any) => ({
              ...prevStickers,
              [props.postId.toString()]: {
                postId: props.postId,
                ownerId: props.ownerId,
                stickerId: sticker.id,
                imageUrl: sticker.image_url,
                ownerUsername: sticker.owner_username,
                ownerAvatarUrl: sticker.owner_avatar_url,
              },
            }));
          }}
          key={sticker.id}
          className={cn(
            "cursor-pointer hover:scale-110 w-8 h-8 aspect-square bg-contain bg-center bg-no-repeat rounded-md transition-all",
            {
              "opacity-50 cursor-not-allowed": sticker.already_posted,
            },
          )}
          style={{ backgroundImage: `url(${sticker.image_url})` }}
        />
      ))}
      {availableStickers.length > maxDisplayStickers && (
        <AvailableStickersButton
          availableStickers={availableStickers.slice(maxDisplayStickers)}
          postId={props.postId}
          ownerId={props.ownerId}
        />
      )}
    </div>
  ) : (
    <div className="border-t border-primary mt-1 pt-4 h-[105px]" />
  );
}

export function AvailableStickersButton(props: {
  postId: string;
  ownerId: string;
  availableStickers: {
    id: string;
    image_url: string;
    already_posted: boolean;
    owner_username: string;
    owner_avatar_url: string;
  }[];
}) {
  const popoverStore = usePopoverStore();
  const mounted = popoverStore.useState("mounted");
  const [, setPostSlappedStickers] = useAtom(postSlappedStickerAtom);

  return (
    <PopoverProvider store={popoverStore} placement="bottom-start">
      <PopoverDisclosure
        toggleOnClick={(e) => {
          e.preventDefault();
          popoverStore.toggle();
          return false;
        }}
        render={
          <button
            type="button"
            className="w-8 h-8 bg-pink-300 flex items-center justify-center rounded-full hover:bg-[#7AFBDA]"
          />
        }
      >
        <Plus size={18} />
      </PopoverDisclosure>
      <AnimatedPopover mounted={mounted} className="w-64">
        <div className="grid grid-cols-5 gap-2 overflow-y-scroll max-h-60 flex-1 p-4">
          {props.availableStickers.map((sticker) => (
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                if (sticker.already_posted) {
                  return;
                }
                setPostSlappedStickers((prevStickers: any) => ({
                  ...prevStickers,
                  [props.postId.toString()]: {
                    postId: props.postId,
                    ownerId: props.ownerId,
                    stickerId: sticker.id,
                    imageUrl: sticker.image_url,
                    ownerUsername: sticker.owner_username,
                    ownerAvatarUrl: sticker.owner_avatar_url,
                  },
                }));
                popoverStore.toggle();
              }}
              key={sticker.id}
              className={cn(
                "cursor-pointer hover:scale-110 aspect-square bg-contain bg-center bg-no-repeat rounded-md transition-all",
                {
                  "opacity-50 cursor-not-allowed": sticker.already_posted,
                },
              )}
              style={{ backgroundImage: `url(${sticker.image_url})` }}
            />
          ))}
        </div>
      </AnimatedPopover>
    </PopoverProvider>
  );
}

interface RecentMentionsProps {
  posts: PostPropType[];
}

export function RecentMentions(props: RecentMentionsProps) {
  return (
    <div className="w-full text-white py-8">
      <h2 className="text-2xl font-bold mb-2">Recent Mentions</h2>
      <div className="grid grid-cols-5 gap-4">
        {props.posts?.map((post: PostPropType) => (
          <div
            key={post.id}
            className="rounded-lg overflow-hidden group bg-[linear-gradient(118deg,#E0FEF6_27.59%,rgba(127,124,255,0.30)_118.93%)] hover:bg-[#BC4CDE] hover:bg-none p-[1px]  transition duration-300 ease-in-out transform hover:scale-105"
          >
            <div className="rounded-lg overflow-hidden bg-white w-full h-36 bg-[linear-gradient(122deg,rgba(255,164,220,0.37)_16.13%,rgba(107,104,255,0.30)_98.86%)] group-hover:bg-[linear-gradient(122deg,#987EF7_16.13%,rgba(107,104,255,0.30)_98.86%)]">
              <Link to={`/post/${post.id}`}>
                <div className="h-24">
                  <MediaCarouselItem mediaUrls={post.media_urls} />
                </div>
                <div className="h-12 flex-wrap p-2 bg-white bg-opacity-75 flex items-center w-full">
                  <div className="flex gap-1 items-center">
                    <img
                      src={post.user_avatar_url || "/default-avatar.svg"}
                      alt={post.user_username}
                      className="w-5 h-5 rounded-full border border-white"
                    />
                    <p className="font-medium text-xs overflow-hidden whitespace-nowrap flex-grow truncate text-black">
                      {post.user_username}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
