import { Dialog } from "@ariakit/react";
import { useNavigate } from "@remix-run/react";
import { Loader2 } from "lucide-react";
import React from "react";
import * as toast from "~/components/toast";
import type { Mention, Tag } from "~/types";
import { cn } from "~/util/css";
import {
  hashtagOnCursor,
  mentionOnCursor,
  stripMentionsFormatter,
  stripTagsAndMentions,
} from "~/util/parse-tags";
import { Button } from "./button";
import TagInput from "./tag-input";

interface EditPostModalProps {
  postContent: string;
  postId: string;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export function EditPostModal(props: EditPostModalProps) {
  const [content, setContent] = React.useState(props.postContent);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, _setErrorMessage] = React.useState("");
  const [tags, setTags] = React.useState<Tag[]>([]);
  const [currentTagQuery, setCurrentTagQuery] = React.useState<string>("");
  const [filteredTags, setFilteredTags] = React.useState<Tag[]>([]);
  const [mentions, setMentions] = React.useState<Mention[]>([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    fetch("/api/tags?count=1000")
      .then((res) => res.json())
      .then((data) => setTags(data.tags))
      .catch(console.error);
  }, []);

  React.useEffect(() => {
    if (!currentTagQuery || currentTagQuery?.length <= 1) {
      setFilteredTags([]);
      return;
    }
    const regex = new RegExp(currentTagQuery, "i");
    const filtered = tags
      .filter((tag: Tag) => regex.test(tag.name))
      .slice(0, 10);
    setFilteredTags(filtered);
  }, [currentTagQuery]);

  const handleSave = async () => {
    setLoading(true);

    const response = await fetch(`/api/posts/${props.postId}`, {
      method: "PATCH",
      body: JSON.stringify({ content }),
    });
    if (response.ok) {
      toast.ok({
        text: "Your post was updated.",
        id: "ok",
      });
      props.onSuccess?.();
      navigate(`/post/${props.postId}`);
    } else {
      toast.fail({
        text: "There was an issue updating post right now. Please try again later.",
        id: "error",
      });
    }
    setLoading(false);
    props.onClose();
  };

  const onTagInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ref: HTMLTextAreaElement | HTMLInputElement,
  ) => {
    const value = e.target.value;

    if (value.length > 160) {
      return;
    }

    const cursorPosition = ref.selectionStart ?? -1;
    const hashtagQuery = hashtagOnCursor(value, cursorPosition);
    const mentionQuery = mentionOnCursor(
      stripTagsAndMentions(value),
      cursorPosition,
    );

    if (hashtagQuery && hashtagQuery.length >= 2)
      setCurrentTagQuery(hashtagQuery);
    else setCurrentTagQuery("");

    if (mentionQuery && mentionQuery.length >= 2) {
      try {
        fetch(`/api/mentions/search?query=${mentionQuery}`)
          .then((res) => res.json())
          .then((data) => setMentions(data.mentions));
      } catch (err) {
        console.error("Error fetching mentions:", err);
      }
    } else {
      setMentions([]);
    }
    setContent(stripMentionsFormatter(value));
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      className={cn(
        "relative w-full lg:w-[540px] mx-auto bg-white rounded-xl border-2 border-black shadow-[10px_10px_0px_0px_#000] overflow-auto mh-[calc(100vh-2rem)]",
      )}
      render={(dialogProps) => (
        <div
          className={cn(
            "fixed z-50 top-0 p-4 w-full h-full bg-pink-300/50 backdrop-blur-sm flex-col justify-center items-center",
            props.open ? "flex" : "hidden",
          )}
        >
          <div {...dialogProps} />
        </div>
      )}
    >
      <form className="p-4 flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <p className="text-lg font-semibold">Edit post</p>
        </div>
        <div className="h-[400px]">
          <TagInput
            value={content}
            onChange={onTagInputChange}
            tags={filteredTags}
            mentions={mentions}
            placeholder="Type your post content here..."
          />
        </div>
        {errorMessage && (
          <p key={errorMessage} className="bg-red-200 rounded-xl p-2">
            {errorMessage}
          </p>
        )}
        <div className="flex items-center justify-end gap-4">
          <button onClick={props.onClose}>Cancel</button>
          <Button onClick={handleSave} disabled={loading || !content}>
            {loading ? (
              <Loader2 size={24} className="animate-spin animate" />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
