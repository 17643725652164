import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import React from "react";
import { commentsByPostIdAtom } from "~/atoms";
import * as toast from "~/components/toast";
import type { Comment, CurrentUser } from "~/types";

export function usePostComments(postId: string, preview?: boolean) {
  const [commentsByPostId, setCommentsByPostId] = useAtom(commentsByPostIdAtom);
  const [loading, setLoading] = React.useState(false);

  const getPostComments = async (): Promise<{ comments?: Comment[] }> => {
    try {
      const res = await fetch(`${window.ENV.EDGE_URL}/posts/${postId}`);
      const data = await res.json();
      return data;
    } catch (err) {
      return {};
    }
  };

  const { data } = useQuery({
    queryKey: ["posts", postId],
    queryFn: getPostComments,
  });

  React.useEffect(() => {
    if (data?.comments?.length) {
      setCommentsByPostId((prev) => ({
        ...prev,
        [postId]: data?.comments || [],
      }));
    }
  }, [data]);

  const addComment = async (content: string, currentUser: CurrentUser) => {
    try {
      if (!currentUser) {
        return;
      }
      setLoading(true);
      const response = await fetch("/api/comments", {
        method: "POST",
        body: JSON.stringify({ postId, comment: content }),
      });
      if (!response.ok) {
        throw new Error("Failed to add comment");
      }
      const data = await response.json();
      const newComment = {
        id: data.comment,
        created_at: new Date().toISOString(),
        content,
        owner_username: currentUser.username,
        owner_avatar_url: currentUser.avatarUrl,
      };
      setCommentsByPostId((prev) => ({
        ...prev,
        [postId]: [...(prev[postId] || []), newComment] as Comment[],
      }));
    } catch (error) {
      console.error("Failed to add comment:", error);
      toast.fail({
        text: "There was an issue adding comment right now. Please try again later.",
        id: "error",
      });
    }
    setLoading(false);
  };

  const deleteComment = async (commentId: number) => {
    try {
      const response = await fetch(`/api/comments/${commentId}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Failed to delete comment");
      }
      setCommentsByPostId((prev) => ({
        ...prev,
        [postId]: prev[postId].filter(
          (comment: Comment) => comment.id !== commentId,
        ),
      }));
      toast.ok({ text: "Comment deleted successfully" });
    } catch (error) {
      console.error("Failed to delete comment:", error);
    }
  };

  return {
    comments: preview
      ? (commentsByPostId[postId] || []).slice(0, 3)
      : commentsByPostId[postId] || [],
    loading,
    addComment,
    deleteComment,
  };
}
