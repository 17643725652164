import { Dialog } from "@ariakit/react";
import { useNavigate } from "@remix-run/react";
import { Loader2 } from "lucide-react";
import React from "react";
import * as toast from "~/components/toast";
import { cn } from "~/util/css";
import { Button } from "./button";

interface DeletePostModalProps {
  postId: string;
  navigateTo: string;
  open: boolean;
  onClose: () => void;
  onDeleteSuccess?: () => void;
}

export function DeletePostModal(props: DeletePostModalProps) {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleDeletePost = async () => {
    setLoading(true);

    const response = await fetch(`/api/posts/${props.postId}`, {
      method: "DELETE",
    });
    if (response.ok) {
      if (props.onDeleteSuccess) {
        props.onDeleteSuccess();
      }

      navigate(props.navigateTo);
      toast.ok({
        text: "Your post was deleted.",
        id: "ok",
      });
    } else {
      toast.fail({
        text: "There was an issue deleting post right now. Please try again later.",
        id: "error",
      });
    }
    setLoading(false);
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      className={cn(
        "relative w-full lg:w-[400px] mx-auto bg-white rounded-xl border-2 border-black shadow-[10px_10px_0px_0px_#000] overflow-auto mh-[calc(100vh-2rem)]",
      )}
      render={(dialogProps) => (
        <div
          className={cn(
            "fixed z-50 top-0 p-4 w-full h-full bg-pink-300/50 backdrop-blur-sm flex-col justify-center items-center",
            props.open ? "flex" : "hidden",
          )}
        >
          <div {...dialogProps} />
        </div>
      )}
    >
      <div className="p-4 flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <p className="text-lg font-semibold">Delete post?</p>
          <p>This can’t be undone and it will be removed from your profile.</p>
        </div>
        <div className="flex items-center justify-end gap-4">
          <button type="button" onClick={props.onClose}>
            Cancel
          </button>
          <Button onClick={handleDeletePost} disabled={loading}>
            {loading ? (
              <Loader2 size={24} className="animate-spin animate" />
            ) : (
              "Delete"
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
